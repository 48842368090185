import Footer from '../Main/Component/Footer';
import Header from '../Main/Component/Header';

const MainLayout = ({ current = "" }) => {
    return (
        <>
            <Header />
            <main className="main"> {current} </main>
            <Footer />
        </>
    );
}

export default MainLayout