const Loader = () => {
    return (
        <div className='loader-block'>
            <div className="loader1">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default Loader