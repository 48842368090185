import React, { useState, useEffect, useCallback, createContext } from "react";
import AxiosHelper from '../Helpers/AxiosHelper'
import useCurrentPath from "../Hooks/useCurrentPath";
const userData = {
    id: null,
    mobile: "",
    name: "",
    email: "",
    image: "",
    status: "",
    multi_delivery: 0,
    users_balance: 0,
    last_login: null,
    admin_balance: 0,
    maximum_allowed_debit_limit: 0,
    pricelevel_id: 0,
    role_id: localStorage.getItem('role_id'),
    rolename: "",
    accessToken: localStorage.getItem('token'),
    UserToNotification: [],
    adminNotification: [],
    cartItems: [],
    permission: []
}

export const userProfileData = createContext(userData)

const UserProfileProvider = (props) => {
    const admin_paths = useCurrentPath(['admin'])
    const customer_paths = useCurrentPath(['customer'])
    const [userProfile, setUserProfile] = useState(userData)

    // Update Data while runing
    const updateData = useCallback(async () => {
        if (admin_paths) {
            const { data } = await AxiosHelper.getData(`/profile`);
            if (data?.status === true) {
                const newData = data?.data
                localStorage.setItem('role_id', newData?.role_id)
                setUserProfile((prevState) => ({ ...prevState, ...newData, accessToken: localStorage.getItem('token') }))
            }
        }

        if (customer_paths) {
            const { data } = await AxiosHelper.getData(`users/profile`);
            if (data?.status === true) {
                const newData = data?.data
                setUserProfile((prevState) => {

                    // console.log(123, ({
                    //     prevState: prevState,
                    //     newData: newData
                    // }));
                    // return ({ ...prevState, ...newData, accessToken: localStorage.getItem('token') })
                    return newData
                })
            }
        }
    }, [customer_paths, admin_paths])

    useEffect(() => { userData.accessToken && updateData() }, [updateData])
    const setData = (data) => setUserProfile({ ...userProfile, ...data })

    const checkPermission = (module_id) => {
        if (userProfile?.role_id === 1) { return true }
        if ([2, 3].includes(userProfile?.role_id)) { return false }
        if (userProfile?.permission?.includes(parseInt(module_id))) { return true }
        return false
    }

    return (
        <userProfileData.Provider value={{ ...userProfile, updateData, setData, checkPermission }}>
            {props.children}
        </userProfileData.Provider>
    )
}


export default UserProfileProvider