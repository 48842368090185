import useSetting from '../../Hooks/useSetting';
const metaDescription = document.querySelector('meta[name="description"]');
const metaTitle = document.querySelector('meta[name="title"]');
const metaIcons = document.querySelectorAll('link[meta="icon"]')
const metaKeywords = document.querySelectorAll('link[meta="keywords"]')


const MetaTags = () => {
    const generalSetting = useSetting();
    // meta Title, Description and Keywords
    metaDescription && (metaDescription.content = generalSetting.meta_description)
    metaTitle && (metaTitle.content = generalSetting.meta_title)
    metaKeywords && (metaKeywords.content = generalSetting.meta_keyword)

    // Dymamic meta Icons
    var i = metaIcons.length;
    if (i > 0) {
        while (i--) {
            metaIcons[i].href = generalSetting.path + generalSetting.favicon;
        }
    }

    // Dynamic Title
    document.title = generalSetting.application_name
    return null
}

export default MetaTags