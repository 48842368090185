import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loader from "../Admin/Component/Loader";
import useProfile from "../Hooks/useProfile";
import Error404 from "../Main/Pages/Error404";

const admin_prefix = process.env.REACT_APP_ADMIN_ROUTE_PREFIX;

const AdminLayout = lazy(() => import("../HOC/AdminLayout"));
const OpenAdmin = lazy(() => import("../HOC/OpenAdmin"));
const Login = lazy(() => import("../Admin/Auth/Login"));
const ForgetPassword = lazy(() => import("../Admin/Auth/ForgetPassword"));
const ResetPassword = lazy(() => import("../Admin/Auth/ResetPassword"));
const Dashboard = lazy(() => import("../Admin/Pages/Dashboard"));
const Profile = lazy(() => import("../Admin/Pages/Profile/Profile"));
const Logout = lazy(() => import("../Admin/Auth/Logout"));

const RolesList = lazy(() => import("../Admin/Pages/Masters/Roles/RolesList"));
const RolePermission = lazy(() => import("../Admin/Pages/Masters/Roles/RolePermission"));

const UsersList = lazy(() => import("../Admin/Pages/Masters/Users/UsersList"));

const FinancialYearList = lazy(() => import("../Admin/Pages/Masters/FinicalYear/FinicalYearList"));
const FirmsList = lazy(() => import("../Admin/Pages/Masters/Firms/FirmsList"));

const CmsList = lazy(() => import("../Admin/Pages/Masters/Cms/CmsList"));
const UpdateCms = lazy(() => import("../Admin/Pages/Masters/Cms/UpdateCms"));
const BannersList = lazy(() => import("../Admin/Pages/Masters/Banners/BannersList"));

const CategoryList = lazy(() => import("../Admin/Pages/Masters/Categories/CategoryList"));
const AddCategory = lazy(() => import("../Admin/Pages/Masters/Categories/AddCategory"));
const EditCategory = lazy(() => import("../Admin/Pages/Masters/Categories/EditCategory"));

const ProductList = lazy(() => import("../Admin/Pages/Masters/Products/ProductList"));
const AddProduct = lazy(() => import("../Admin/Pages/Masters/Products/AddProduct"));
const EditProduct = lazy(() => import("../Admin/Pages/Masters/Products/EditProduct"));
const UploadImages = lazy(() => import("../Admin/Pages/Masters/Products/UploadImages"));
const ProductPriceLevels = lazy(() => import("../Admin/Pages/Masters/Products/ProductPriceLevels"));

const InvoiceItems = lazy(() => import("../Admin/Pages/Masters/InvoiceItems/ListInvoiceItems"));
const ListGSTRate = lazy(() => import("../Admin/Pages/Masters/GSTRate/ListGSTRate"));
const GSTInvoice = lazy(() => import("../Admin/Pages/Ledgers/GSTInvoice"));

const CustomerList = lazy(() => import("../Admin/Pages/Customers/CustomerList"));
const CustomerOrders = lazy(() => import("../Admin/Pages/Customers/CustomerOrders"));
const CustomerAddress = lazy(() => import("../Admin/Pages/Customers/CustomerAddress"));
const CustomerLedger = lazy(() => import("../Admin/Pages/Customers/CustomerLedger"));

const VendorList = lazy(() => import("../Admin/Pages/Vendors/VendorList"));
const VendorLedger = lazy(() => import("../Admin/Pages/Vendors/VendorLedger"));

const PurchaseBillsList = lazy(() => import("../Admin/Pages/PurchaseBills/PurchaseBillsList"));
const PurchaseBillDetails = lazy(() => import("../Admin/Pages/PurchaseBills/PurchaseBillDetails"));

const OrderList = lazy(() => import("../Admin/Pages/Orders/OrderList"));
const AddOrder = lazy(() => import("../Admin/Pages/Orders/AddOrder"));
const EditOrder = lazy(() => import("../Admin/Pages/Orders/EditOrder"));
const OrderListPrinting = lazy(() => import("../Admin/Pages/Orders/OrderListPrinting"));
const OrderListDelivery = lazy(() => import("../Admin/Pages/Orders/OrderListDelivery"));
const OrderListUnderProcess = lazy(() => import("../Admin/Pages/Orders/OrderListUnderProcess"));

const OrderProducts = lazy(() => import("../Admin/Pages/Orders/OrderProducts"));
const OrderInvoice = lazy(() => import("../Admin/Pages/Orders/OrderInvoice"));

const CustomerLedgers = lazy(() => import("../Admin/Pages/Ledgers/CustomerLedgers"));
const VendorLedgers = lazy(() => import("../Admin/Pages/Ledgers/VendorLedgers"));
const PrinterLedgers = lazy(() => import("../Admin/Pages/Ledgers/PrinterLedgers"));
const PrintLedger = lazy(() => import("../Admin/Pages/Ledgers/PrintLedger"));

const ListFaqType = lazy(() => import("../Admin/Pages/Masters/HeIpFAQ/ListFaqType"));
const ListFAQ = lazy(() => import("../Admin/Pages/Masters/HeIpFAQ/ListFAQ"));

const SendNotification = lazy(() => import("../Admin/Pages/Masters/Notifications/SendNotification"));
const MyNotifications = lazy(() => import("../Admin/Pages/Profile/MyNotifications"));

const PaymentsEnquiries = lazy(() => import("../Admin/Pages/SupportTicket/PaymentsEnquiries"));
const TicketTypeList = lazy(() => import("../Admin/Pages/TicketType/TicketTypeList"));
const TicketList = lazy(() => import("../Admin/Pages/SupportTicket/TicketList"));

const Sales = lazy(() => import("../Admin/Pages/Reports/Sales"));
const Purchase = lazy(() => import("../Admin/Pages/Reports/Purchase"));

const GeneralSettings = lazy(() => import("../Admin/Pages/SiteSetting/GeneralSettings"))
const BackupDatabase = lazy(() => import("../Admin/Pages/SiteSetting/BackupDatabase"));
const PriceLevelList = lazy(() => import("../Admin/Pages/Masters/PriceLevel/PriceLevelList"));

const PrintingTasks = lazy(() => import("../Admin/Pages/PrintingTasks/PrintingTasks"));
const PrintingLedger = lazy(() => import("../Admin/Pages/PrintingTasks/PrintingLedger"));
const PrintTaskUpdate = lazy(() => import("../Admin/Pages/PrintingTasks/PrintTaskUpdate"));
const DeliveryTasks = lazy(() => import("../Admin/Pages/DeliveryTasks/DeliveryTasks"));
const DeliveryTasksUpdate = lazy(() => import("../Admin/Pages/DeliveryTasks/DeliveryTasksUpdate"));

const LowStockProductList = lazy(() => import("../Admin/Pages/Masters/Products/LowStockProductList"));
const GSTInvoicesList = lazy(() => import("../Admin/Pages/GSTInvoice/GSTInvoicesList"));

export default function AdminRoutes() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path={admin_prefix} element={<OpenAdmin current={<Outlet />} />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgetPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
          </Route>

          <Route path={admin_prefix} element={<AdminLayout current={<Outlet />} />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard module_id={true} />} />
            <Route path="profile" element={<Profile module_id={true} />} />
            <Route path="my-notifications" element={<MyNotifications module_id={true} />} />
            <Route path="logout" element={<Logout module_id={true} />} />

            <Route path="user-roles" foo="bar" element={<RolesList />} />
            <Route path="permission/:slug" element={<RolePermission />} />
            <Route path="users" element={<UsersList />} />
            <Route path="financial-year" element={<FinancialYearList />} />
            <Route path="price-level" element={<PriceLevelList />} />
            <Route path="firms" element={<FirmsList />} />
            <Route path="cms-pages" element={<CmsList />} />
            <Route path="cms-pages/:slug" element={<UpdateCms />} />
            <Route path="banners" element={<BannersList />} />
            <Route path="product-categories" element={<CategoryList />} />
            <Route path="product-categories/add" element={<AddCategory />} />
            <Route path="product-categories/edit/:slug" element={<EditCategory />} />

            <Route path="products" element={<ProductList />} />
            <Route path="products-low-stock" element={<LowStockProductList />} />
            <Route path="products/add" element={<AddProduct />} />
            <Route path="products/edit/:slug" element={<EditProduct />} />
            <Route path="products/images/:slug" element={<UploadImages />} />
            <Route path="products/price-levels/:slug" element={<ProductPriceLevels />} />

            <Route path="gst-rates" element={<ListGSTRate />} />
            <Route path="invoice-items" element={<InvoiceItems />} />
            <Route path="gst-invoice/:slug" element={<GSTInvoice />} />

            <Route path="customers" element={<CustomerList />} />
            <Route path="customers/address/:slug" element={<CustomerAddress />} />
            <Route path="customers/orders/:slug" element={<CustomerOrders />} />
            <Route path="customers/ledger/:slug" element={<CustomerLedger />} />

            <Route path="vendors" element={<VendorList />} />
            <Route path="vendors/ledger/:slug" element={<VendorLedger />} />
            <Route path="purchase-bills" element={<PurchaseBillsList />} />
            <Route path="purchase-bills/:slug" element={<PurchaseBillDetails />} />

            <Route path="ledger/customer" element={<CustomerLedgers />} />
            <Route path="ledger/vendor" element={<VendorLedgers />} />
            <Route path="ledger/printer" element={<PrinterLedgers />} />
            <Route path="ledger/print/:slug" element={<PrintLedger />} />

            <Route path="orders" element={<OrderList />} />
            <Route path="orders/add" element={<AddOrder />} />
            <Route path="orders/:slug" element={<EditOrder />} />
            <Route path="orders/printing" element={<OrderListPrinting />} />
            <Route path="orders/delivery" element={<OrderListDelivery />} />
            <Route path="orders/under-process" element={<OrderListUnderProcess />} />

            <Route path="order/products/:slug" element={<OrderProducts />} />
            <Route path="order/invoice/:slug" element={<OrderInvoice />} />

            <Route path="faqs-type" element={<ListFaqType />} />
            <Route path="help-and-faqs" element={<ListFAQ />} />

            <Route path="notifications" element={<SendNotification />} />
            <Route path="gst-invoices" element={<GSTInvoicesList />} />

            <Route path="ticket/ticket-types" element={<TicketTypeList />} />
            <Route path="ticket" element={<TicketList />} />
            <Route path="payments-enquiries" element={<PaymentsEnquiries />} />

            <Route path="report/sale" element={<Sales />} />
            <Route path="report/purchase" element={<Purchase />} />

            <Route path="application-setting/:key" element={<GeneralSettings />} />
            <Route path="backup-database" element={<BackupDatabase />} />

            {/* For Printing Routes */}
            <Route path="" element={<PrintingLayout current={<Outlet />} />}>
              <Route path="printing-tasks" element={<PrintingTasks />} />
              <Route path="printing-ledger" element={<PrintingLedger />} />
              <Route path="printing-tasks/:slug" element={<PrintTaskUpdate />} />
            </Route>

            {/* For Delivery Routes */}
            <Route path="" element={<DeliveryLayout current={<Outlet />} />}>
              <Route path="delivery-tasks" element={<DeliveryTasks />} />
              <Route path="delivery-tasks/:slug" element={<DeliveryTasksUpdate />} />
            </Route>
          </Route>

          <Route path={`${admin_prefix}/*`} element={<Error404 />} />
          <Route path="*" element={<></>} />
        </Routes>
      </Suspense>
    </>
  );
}

const PrintingLayout = ({ current }) => {
  const location = useLocation();
  const { role_id } = useProfile()
  return parseInt(role_id) === 2 ? current : <Navigate to={`${admin_prefix}/dashboard`} state={{ from: location }} replace />
}

const DeliveryLayout = ({ current }) => {
  const location = useLocation();
  const { role_id } = useProfile()
  return parseInt(role_id) === 3 ? current : <Navigate to={`${admin_prefix}/dashboard`} state={{ from: location }} replace />
}
