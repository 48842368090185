import { lazy, Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import MainLayout from "../HOC/MainLayout";
import Loader from "../Admin/Component/Loader";
import useCurrentPath from "../Hooks/useCurrentPath";

const Error404 = lazy(() => import("../Main/Pages/Error404"));
const Home = lazy(() => import("../Main/Pages/Home"));
const ContactUs = lazy(() => import("../Main/Pages/ContactUs"));
const CMS = lazy(() => import("../Main/Pages/CMS"));
// const Services = lazy(() => import("../Main/Pages/Services"));
// const Maintenance = lazy(() => import("../Main/Pages/Maintenance"));


export default function WebRoutes() {
	const path = useCurrentPath(['admin', 'admin_open', 'customer', 'customer_open']);
	return (
		<>
			{(path === null) ?
				<Suspense fallback={<Loader />}>
					<Routes>
						{/* <Route path="/" element={<Maintenance />} /> */}
						<Route path="/" element={<MainLayout current={<Outlet />} />}>
							<Route index element={<Home />} />
							<Route path="home" element={<Home />} />
							<Route path="about" element={<CMS type="about-us" />} />
							<Route path="contact-us" element={<ContactUs />} />
							<Route path="our-services" element={<CMS type="our-services" />} />
							<Route path="privacy-policy" element={<CMS type="privacy-policy" />} />
							<Route path="terms-conditions" element={<CMS type="terms-conditions" />} />
							<Route path="delivery-information" element={<CMS type="delivery-information" />} />
							<Route path="returns-exchanges" element={<CMS type="returns-exchanges" />} />
						</Route>


						<Route path='*' element={<Error404 home_page="home" theme="orange" />} />
					</Routes>
				</Suspense>
				: null
			}
		</>
	);
}

