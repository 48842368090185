import React from "react";
import WebRoutes from "./WebRoutes";
import AdminRoutes from "./AdminRoutes";
import CustomerRoutes from "./CustomerRoutes";
import CommonProvider from "../Context/CommonProvider";
import ReactTooltip from "react-tooltip";
import MetaTags from "../Admin/Component/MetaTags";
import { ToastContainer } from 'react-toastify';

export default function AllRoutes() {
  return (
    <CommonProvider>
      <AdminRoutes />
      <CustomerRoutes />
      <WebRoutes />

      <MetaTags />
      <ReactTooltip />
      <ToastContainer pauseOnHover position="bottom-center" autoClose={5000} />
    </CommonProvider>
  );
}
