import { matchRoutes, useLocation } from "react-router-dom"
const admin_prefix = process.env.REACT_APP_ADMIN_ROUTE_PREFIX;
const customer_prefix = process.env.REACT_APP_CUSTOMER_ROUTE_PREFIX;

const routes = [
    // Admin Without Permission
    { path: admin_prefix, module_id: true },
    { path: admin_prefix + '/', module_id: true },
    { path: admin_prefix + '/dashboard', module_id: true },
    { path: admin_prefix + '/profile', module_id: true },
    { path: admin_prefix + '/my-notifications', module_id: true },
    { path: admin_prefix + '/logout', module_id: true },

    // Admin With Permission
    { path: admin_prefix + '/user-roles', module_id: 101 },
    { path: admin_prefix + '/permission/:slug', module_id: 105 },
    { path: admin_prefix + '/users', module_id: 201 },
    { path: admin_prefix + '/financial-year', module_id: 301 },
    { path: admin_prefix + '/price-level', module_id: 401 },
    { path: admin_prefix + '/firms', module_id: 701 },
    { path: admin_prefix + '/cms-pages', module_id: 2001 },
    { path: admin_prefix + '/cms-pages/:slug', module_id: 2001 },
    { path: admin_prefix + '/banners', module_id: 2001 },
    { path: admin_prefix + '/product-categories', module_id: 501 },
    { path: admin_prefix + '/product-categories/add', module_id: 502 },
    { path: admin_prefix + '/product-categories/edit/:slug', module_id: 503 },
    { path: admin_prefix + '/products', module_id: 601 },
    { path: admin_prefix + '/products-low-stock', module_id: 601 },
    { path: admin_prefix + '/products/add', module_id: 602 },
    { path: admin_prefix + '/products/edit/:slug', module_id: 603 },
    { path: admin_prefix + '/products/images/:slug', module_id: 607 },
    { path: admin_prefix + '/products/price-levels/:slug', module_id: 606 },
    { path: admin_prefix + '/customers', module_id: 1001 },
    { path: admin_prefix + '/customers/address/:slug', module_id: 1101 },
    { path: admin_prefix + '/customers/ledger/:slug', module_id: 1501 },
    { path: admin_prefix + '/vendors', module_id: 1301 },
    { path: admin_prefix + '/vendors/ledger/:slug', module_id: 1601 },
    { path: admin_prefix + '/ledger/customer', module_id: 1501 },
    { path: admin_prefix + '/ledger/vendor', module_id: 1601 },
    { path: admin_prefix + '/ledger/printer', module_id: 1401 },
    { path: admin_prefix + '/ledger/print/:slug', module_id: true },
    { path: admin_prefix + '/orders', module_id: 1801 },
    { path: admin_prefix + '/orders/add', module_id: 1802 },
    { path: admin_prefix + '/orders/:slug', module_id: 1903 },
    { path: admin_prefix + '/orders/printing', module_id: 1801 },
    { path: admin_prefix + '/orders/delivery', module_id: 1801 },
    { path: admin_prefix + '/orders/under-process', module_id: 1801 },
    { path: admin_prefix + '/order/invoice/:slug', module_id: 1801 },
    { path: admin_prefix + '/order/products/:slug', module_id: 1901 },
    { path: admin_prefix + '/faqs-type', module_id: 801 },
    { path: admin_prefix + '/help-and-faqs', module_id: 901 },
    { path: admin_prefix + '/notifications', module_id: 2101 },
    { path: admin_prefix + '/ticket/ticket-types', module_id: 1701 },
    { path: admin_prefix + '/ticket', module_id: 2201 },
    { path: admin_prefix + '/payments-enquiries', module_id: 1201 },
    { path: admin_prefix + '/application-setting/:key', module_id: [2301, 2401, 2501, 2601, 2701, 2801] },
    { path: admin_prefix + '/backup-database', module_id: 2901 },
    { path: admin_prefix + '/purchase-bills', module_id: 3001 },
    { path: admin_prefix + '/purchase-bills/:slug', module_id: 3003 },
    { path: admin_prefix + '/report/sale', module_id: 3201 },
    { path: admin_prefix + '/report/purchase', purchase: 3202 },
    { path: admin_prefix + '/invoice-items', module_id: 3301 },
    { path: admin_prefix + '/gst-rates', module_id: 3401 },
    { path: admin_prefix + '/gst-invoice/:slug', module_id: 3502 },
    { path: admin_prefix + '/gst-invoices', module_id: 3501 },

    // Admin With Permission
    { path: admin_prefix + '/printing-tasks', module_id: true },
    { path: admin_prefix + '/printing-ledger', module_id: true },
    { path: admin_prefix + '/printing-tasks/:slug', module_id: true },

    { path: admin_prefix + '/delivery-tasks', module_id: true },
    { path: admin_prefix + '/delivery-tasks/:slug', module_id: true },
]


const routes_customer = [
    { path: customer_prefix, module_id: true },
    { path: customer_prefix + '/', module_id: true },
    { path: customer_prefix + '/home', module_id: true },
    { path: customer_prefix + '/profile', module_id: true },
    { path: customer_prefix + '/change-password', module_id: true },
    { path: customer_prefix + '/addresses', module_id: true },
    { path: customer_prefix + '/wishlist', module_id: true },
    { path: customer_prefix + '/ledger', module_id: true },
    { path: customer_prefix + '/invoice', module_id: true },
    { path: customer_prefix + '/invoice/:slug', module_id: true },
    { path: customer_prefix + '/create-order', module_id: true },
    { path: customer_prefix + '/notifications', module_id: true },
    { path: customer_prefix + '/tickets', module_id: true },
    { path: customer_prefix + '/payments-enquiries', module_id: true },
    { path: customer_prefix + '/my-orders', module_id: true },
    { path: customer_prefix + '/checkout', module_id: true },
    { path: customer_prefix + '/products', module_id: true },
    { path: customer_prefix + '/products/:slug', module_id: true },
    { path: customer_prefix + '/logout', module_id: true },
]

const routes_customer_open = [
    { path: customer_prefix + '/login', module_id: true },
    { path: customer_prefix + '/forgot-password', module_id: true },
    { path: customer_prefix + '/reset-password/:token', module_id: true },
    { path: customer_prefix + '/*', module_id: true },
]

const routes_admin_open = [
    { path: admin_prefix + '/login', module_id: true },
    { path: admin_prefix + '/forgot-password', module_id: true },
    { path: admin_prefix + '/reset-password/:token', module_id: true },
    { path: admin_prefix + '/*', module_id: true },
]

const routes_open = [
    { path: '/', module_id: true },
    { path: '/home', module_id: true },
    { path: '/about', module_id: true },
    { path: '/contact-us', module_id: true },
    { path: '/our-services', module_id: true },
    { path: '/privacy-policy', module_id: true },
    { path: '/terms-conditions', module_id: true },
    { path: '/delivery-information', module_id: true },
    { path: '/returns-exchanges', module_id: true },
]

const useCurrentPath = (types = ['admin']) => {
    var array_merge = [];
    const location = useLocation()
    if (types.includes('admin')) {
        array_merge = array_merge.concat(routes)
    }

    if (types.includes('admin_open')) {
        array_merge = array_merge.concat(routes_admin_open)
    }

    if (types.includes('customer')) {
        array_merge = array_merge.concat(routes_customer)
    }

    if (types.includes('customer_open')) {
        array_merge = array_merge.concat(routes_customer_open)
    }

    if (types.includes('open')) {
        array_merge = array_merge.concat(routes_open)
    }

    var data = matchRoutes(array_merge, location)
    if (data) { var [{ route }] = data }
    return data ? route : null
}

export default useCurrentPath